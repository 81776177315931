import React from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import firebase from "./firebase-tools";

import {
  AuthContextProvider,
  useAuthContext,
  session,
} from "./AuthContextProvider";

const ROUTE_LOGIN = "/login";
const ROUTE_DEFAULT = "/";

const Login = () => {
  const doSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const el = document.getElementById("email") as HTMLInputElement;
    const email = el?.value;
    session
      .login(email)
      .then((success) =>
        alert(success ? `Sent email to ${email}` : "Error sending email")
      );
  };

  return (
    <form style={{ margin: "10px" }} onSubmit={doSubmit}>
      <input id="email" type="text" placeholder="email" />
      <br />
      <button type="submit" style={{ marginTop: "10px" }}>
        Get Login Link
      </button>
    </form>
  );
};

const Main = () => {
  const app = useAuthContext();

  return (
    <div style={{ margin: "10px" }}>
      <p>Loaded: {app.isLoaded ? "true" : "false"}</p>
      {app.user && (
        <div>
          <p>UID: {app.user?.uid}</p>
          <p>Email: {app.user?.email}</p>
          <button
            onClick={() => {
              const url = prompt("URL");
              window.open(
                session.URL("go", {
                  url: url || window.location.href,
                })
              );
            }}
          >
            Go
          </button>
          <br />
          <button
            style={{ margin: "10px 0" }}
            onClick={() => firebase.auth().signOut()}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <Router>
      <AuthContextProvider
        loginRoute={ROUTE_LOGIN}
        defaultRoute={ROUTE_DEFAULT}
      >
        <Switch>
          <Route path="/test">
            <div>Test Route</div>
          </Route>
          <Route path={ROUTE_LOGIN}>
            <Login />
          </Route>
          <Route path={ROUTE_DEFAULT}>
            <Main />
          </Route>
        </Switch>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
