import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  FC,
  //Dispatch,
  //SetStateAction,
} from "react";

import { useHistory } from "react-router";

import firebase from "./firebase-tools";
import * as session from "./Session";

const DEFAULT_ROUTE = "/";
const DEFAULT_ROUTE_LOGIN = "/login";

export interface AppContextProps {
  /**
   * True if we're done attempting to negotiate automatic login
   */
  isLoaded: boolean;

  /**
   * True if a valid user object is present
   */
  isLoggedIn: boolean;

  /**
   * The logged in user, if there is one.
   *
   * This is actually a tri-state object:
   *  1) undefined - Initial state, log in status unknown yet
   *  2) null - Definitively not logged in
   *  3) user - The logged in user object
   */
  user: firebase.User | null | undefined;
}

export interface LoginParams {
  /**
   * Email of user
   */
  email: string;

  /**
   * Target path for redirect
   */
  to: string | null;
}

const AuthContext = createContext<AppContextProps>({
  user: undefined,
  isLoggedIn: false,
  isLoaded: true,
});

const getParams = (search: string): LoginParams => {
  const parsed = new URLSearchParams(search);
  const params = {
    email: parsed.get("email") || "",
    to: parsed.get("to"),
  };

  return params;
};

const handleRedirect = async (
  to: string | null,
  history: ReturnType<typeof useHistory>
) => {
  if (to) {
    if (to.match(/^https?:\/\//i)) {
      window.location.href = session.URL("go", { url: to });
      return;
    }

    history.push(to);
  }
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface AuthContextProviderProps {
  /**
   * Location to redirect user to if automatic sign-in is unsuccessful.
   */
  loginRoute?: string;

  /**
   * Location to redirect the user to if automatic sign-in is successful.
   */
  defaultRoute?: string;
}

export const AuthContextProvider: FC<AuthContextProviderProps> = (
  props = { loginRoute: DEFAULT_ROUTE_LOGIN, defaultRoute: DEFAULT_ROUTE }
) => {
  const [user, setUser] = useState<AppContextProps["user"]>();
  const prevUser = usePrevious(user);

  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const { search } = window.location;
    const params = getParams(search);
    const isMagicLink = firebase.auth().isSignInWithEmailLink(search);

    return firebase.auth().onAuthStateChanged(async (user) => {
      if (prevUser === user) {
        return;
      }

      console.log(`User State: ${prevUser} -> ${user}`);

      let to: string | null = null;

      if (prevUser === undefined) {
        if (user === null) {
          // Try to sign-in the user
          if (await session.resume({ search })) {
            // Automatic sign-in successful -- bail and wait for next call
            // to set the state
            return;
          }

          // Automatic sign-in failed.  Redirect to login page?
          if (props.loginRoute) {
            to = props.loginRoute;
          }
        }
      }

      if (prevUser && !user) {
        // User signed out, destroy session cookie as well
        await session.end();
      }

      if (user) {
        if (isMagicLink && params.email && user.email !== params.email) {
          // User account mismatch
          await session.end(true);
          return;
        }

        // Set/update the session cookie
        await session.init(user);

        if (isMagicLink && !to) {
          to = props.defaultRoute || DEFAULT_ROUTE;
        }
      }

      handleRedirect(to, history);

      // Finally set the user state
      setUser(user);
      setIsLoaded(true);
    });
  }, [prevUser, history, props.defaultRoute, props.loginRoute]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn: !!user,
        isLoaded: isLoaded,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export {session};
