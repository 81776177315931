import firebase from "./firebase-tools";

type URLActions = "init" | "end" | "go" | "resume" | "login";

//interface URLParams {
//  action: "init" | "end" | "go" | "resume";
//  // TODO: Define this better
//  params?: Record<string, string>;
//}
//
//interface URLParamsInit {
//  idToken: string;
//  user?: firebase.User;
//}
//
//interface URLParamsEnd {
//  signOut?: boolean;
//}

interface HttpErrorWireFormat {
  message: string;
  status: string;
}

class HttpError extends Error {
  public error: HttpErrorWireFormat;

  constructor(error: HttpErrorWireFormat) {
    super(error.message);
    this.error = error;
  }
}

const formatError = (
  error: HttpError | HttpErrorWireFormat | string,
  defaultError: HttpErrorWireFormat = {
    message: "Internal error",
    status: "INTERNAL",
  }
) => {
  if (!(error instanceof HttpError)) {
    const keys = Object.getOwnPropertyNames(error || {});
    if (keys.includes("message") && keys.includes("status")) {
      error = new HttpError(error as HttpErrorWireFormat);
    } else {
      error = new HttpError(defaultError);
    }
  }
  return error;
};

export function URL(action: "init" | "end" | "resume" | "login"): string;
export function URL(action: "go", params: { url: string }): string;
export function URL(
  action: URLActions,
  params?: Record<string, string>
): string {
  const url = new global.URL("/session", window.location.origin);
  url.searchParams.set("action", action);
  Object.entries(params || {}).forEach(([key, value]) =>
    url.searchParams.set(key, value)
  );
  return url.toString();
}

export const callApi = async (url: string, params: RequestInit = {}) =>
  fetch(url, {
    ...{ method: "POST", headers: { "content-type": "application/json" } },
    ...params,
  })
    .then((res) =>
      res.json().then((data) => {
        if (res.status >= 400) {
          throw formatError(data.error);
        }
        return data;
      })
    )
    .catch((error) => {
      throw formatError(error, { message: error.message, status: "INTERNAL" });
    });

/**
 * Initialize a session by setting a session cookie.
 * This method does not throw an error.
 */
export async function init(idToken: string): Promise<boolean>;
export async function init(user: firebase.User): Promise<boolean>;
export async function init(
  userOrIdToken: string | firebase.User
): Promise<boolean> {
  const idToken =
    typeof userOrIdToken === "string"
      ? userOrIdToken
      : await userOrIdToken?.getIdToken().catch(() => "");

  if (idToken) {
    return callApi(URL("init"), {
      body: JSON.stringify({ idToken }),
    })
      .then(() => true)
      .catch(() => false);
  }

  return false;
}

/**
 * End a session by removing the session cookie and optionally
 * signing out of Firebase.  This method does not throw an error.
 */
export const end = async (signOut = false): Promise<void> =>
  fetch(URL("end"), { method: "POST" })
    .then(() => {
      signOut === true &&
        firebase
          .auth()
          .signOut()
          .catch((error) => console.error("Error signing out", error));
    })
    .catch((error) => {
      console.error("Error ending session", error);
    });

/**
 * Resume the user's session using cookies and optionally a magic link.  If
 * successful, returns the user, null otherwise.  If "search" params are
 * supplied, at attempt will be made to sign-in the user using a magic link.
 * If not supplied, or the attempt fails, then an attempt will be made to
 * sign-in the user using the session cookie.
 *
 * This method does not throw an error.
 */
export const resume = async (
  opts: {
    /**
     * Search params from query string.  If supplied an attempt will be made to
     * login using magic link.
     */
    search?: string;

    /**
     * Used in conjunction with the "search" params for magic link sign-in.  If
     * not provided, an attempt will be made to find the email in the "search".
     */
    email?: string;
  } = {}
): Promise<firebase.User | null> => {
  let user: firebase.User | null = null;

  if (opts.search) {
    if (firebase.auth().isSignInWithEmailLink(opts.search)) {
      console.log("Found magic link");
      let { email } = opts;
      if (!email) {
        const query = new URLSearchParams(opts.search);
        email = query.get("email") || "";
      }

      if (email) {
        user = await firebase
          .auth()
          .signInWithEmailLink(email, opts.search)
          .then(({ user }) => user)
          .catch((error) => {
            console.error("Error with magic link", error);
            return null;
          });
      }
    }
  }

  if (!user) {
    user = await callApi(URL("resume"))
      .then((data) =>
        data.token
          ? firebase
              .auth()
              .signInWithCustomToken(data.token)
              .then(({ user }) => user)
              .catch((error) => {
                console.error("Error signing in with token", error);
                return null;
              })
          : null
      )
      .catch(() => null);
  }

  return user;
};

/**
 * Send a login link to the user's email.  If this returns false, the request
 * did not succeed.  This can happen if the email address is invalid OR if
 * are too many successive requests with the same email address.  This method
 * should not be called more than once per 10 seconds.
 *
 * This method does not throw an error.
 */
export const login = async (email: string, to?: string): Promise<boolean> =>
  callApi(URL("login"), {
    body: JSON.stringify({ email, to: to ? to : window.location.origin }),
  })
    .then(() => true)
    .catch(() => false);
