import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  //apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  //authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  //databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  //projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  //storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  //messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  //appId: process.env.REACT_APP_FIREBASE_APPID,
  //measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  "projectId": "set-live-stage",
  "appId": "1:321388924990:web:b1c3aba9ab90a83d20c16f",
  "databaseURL": "https://set-live-stage.firebaseio.com",
  "storageBucket": "set-live-stage.appspot.com",
  "locationId": "us-central",
  "apiKey": "AIzaSyC_WUpwAD8lZt3q2Hm6Rwkd53NNHSOJStY",
  "authDomain": "set-live-stage.firebaseapp.com",
  "messagingSenderId": "321388924990",
  "measurementId": "G-2JS3TBMJC9"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage();

// XXX: For testing
//firebase.auth().useEmulator('http://localhost:9099');

export const fb = firebase;
export const fstore = firebase.firestore();

export default firebase;
